import axios from 'axios';
import * as preProcessorType from './PreProcessingActionTypes';
import { toast } from 'react-toastify';
import { PATH, PRODUCT_URL } from '../../Constants';
import { redirectToLogin } from '../Common/CommonAction';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');

export const preProcessing = () => async (dispatch) => {
  const caseId = { cs_id: sessionStorage.getItem('caseId') };
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.caseProcessing}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: caseId,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// verify case data
export const verifyCaseData = () => async (dispatch) => {
  // dispatch({
  //   type: preProcessorType.PREPROCESSING_DATA,
  //   payload: [],
  // });
  const caseId = { cs_id: sessionStorage.getItem('caseId') };
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.verify_casedata}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: caseId,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.message);
      let result = JSON.parse(
        JSON.stringify(api?.data?.image_preprocessing_response)
      );
      Object.keys(result).forEach((key) => {
        result[key].img_dir_path = result[key].img_dir_path.replace(
          '/home/ubuntu/Credit/credit_app',
          ''
        );
        Object.assign({}, result, result);
      });
      //let doc_type = Object.entries(api.data.document_types);
      dispatch({
        type: preProcessorType.PREPROCESSING_DATA,
        payload: [result, api.data.document_types, api.data.queue_type, api.data.image_quality_check],
      });
      console.log(api, 'hi there');
      //   dispatch()
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const extractionProcessApi = (set) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.extractionProcess}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: set,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// for multi select caseids extraction process
export const multiSelectExtractionProcess = (caseIds) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.extractionProcess}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {cs_id: caseIds},
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Update case data
export const updateCaseData = (data) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.update_casedata}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// * Reject case
export const rejectCase = (data) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.rejectstatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      //await window.location.reload();
      return true;
    } else {
      toast.success(api.data.message);
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Reject job
export const rejectJob = (data) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.reject_job}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      await window.location.reload();
      return true;
    } else {
      toast.success(api.data.message);
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Splitting and Merging
export const splitMerge = (data) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.split_merge}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      // let result = JSON.parse(
      //   JSON.stringify(api?.data?.verifycasedata?.image_preprocessing_response)
      // );
      // Object.keys(result).forEach((key) => {
      //   result[key].img_dir_path = result[key].img_dir_path.replace(
      //     '/home/azureuser/Credit/credit_app',
      //     ''
      //   );
      //   Object.assign({}, result, result);
      // });
      // dispatch({
      //   type: preProcessorType.PREPROCESSING_DATA,
      //   payload: [
      //     result,
      //     api.data.verifycasedata.document_types,
      //     api.data.verifycasedata.queue_type,
      //   ],
      // });
      return true;
    } else {
      toast.error(api.data.message);
      return false;
    }
  } catch (error) {
    toast.error(error.response.data.message)
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const resetPreProcessing = () => (dispatch) => {
  return dispatch({
    type: preProcessorType.PREPROCESSING_DATA,
    payload: [],
  });
};

export const addNewPreProcessing =
  (newJobId, docType) => (dispatch, getState) => {
    const { currentProcessed } = getState().preProcess;
    dispatch({
      type: preProcessorType.PREPROCESSING_DATA,
      payload: [
        ...currentProcessed,
        (currentProcessed[0][newJobId] = {
          filepath: '',
          img_dir_path: '',
          is_readable: true,
          images_count: 1,
        }),
        (currentProcessed[1][newJobId] = docType),
      ],
    });
  };

export const updatePreProcessing = (jobId, docType) => (dispatch, getState) => {
  const { currentProcessed } = getState().preProcess;
  dispatch({
    type: preProcessorType.PREPROCESSING_DATA,
    payload: [...currentProcessed, (currentProcessed[1][jobId] = docType)],
  });
};
export const deletePreProcessing = (data) => (dispatch, getState) => {
  const { currentProcessed } = getState().preProcess;
  dispatch({
    type: preProcessorType.PREPROCESSING_DATA,
    payload: data,
  });
};

// delete unknow doc

export const DeleteUnknownDocuments = () => async (dispatch) => {
  const data1 = { case_id: sessionStorage.getItem('caseId') };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.delete_unknown}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success('Unknow Documents Deleted successfully');
      return true;
    } else {
      toast.error('Something Went Wrong');

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};