import axios from 'axios';
import * as queuesActionType from './queuesActionTypes';
import { toast } from 'react-toastify';
import { PATH, PRODUCT_URL } from '../../Constants';
import { createJsonHeader } from '../User/userAction';
import { redirectToLogin } from '../Common/CommonAction';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');

export const getQueuesData = (page, rowsindex) => async (dispatch) => {
  // dispatch({
  //     type: queuesActionType.QUEUES_DATA,
  //     payload: []
  // })
  const data1 = {
    page_no: parseInt(page + 1),
    rows_per_page: parseInt(rowsindex) || 2,
  };
  const data = {
    admin_email,
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.display_queues}`,
    data,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    // data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log('check Api', api);
      let data = [];
      api.data.Queues.map((e) => {
        data.push([
          e.name,
          e.document_types,
          e.cases,
          e.processor,
          e.id,
          e.document_types_list,
          e.processor_list,
        ]);
      });
      // setDisplayProcessor(data);
      dispatch({
        type: queuesActionType.QUEUES_DATA,
        payload: data,
      });
      dispatch({
        type: queuesActionType.QUEUES_LENGTH,
        payload: api.data.Total_Queues,
      });
      toast.error(api.data.message);
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: queuesActionType.QUEUES_DATA,
        payload: [],
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: queuesActionType.QUEUES_DATA,
        payload: [],
      });
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const setQueueName = (fields) => async (dispatch) => {
  dispatch({
    type: queuesActionType.SET_QUEUE_NAME,
    payload: fields,
  });
};

export const fieldInfo = (fields) => async (dispatch) => {
  dispatch({
    type: queuesActionType.ADD_QUEUES_DATA,
    payload: fields,
  });
};

// * Hide checkboxes for certain tables
export const hideCheckboxes = (payload) => async (dispatch) => {
  dispatch({
    type: queuesActionType.HIDE_CHECKBOXES,
    payload,
  });
};

export const addQueues = async (fields) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.add_queues}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    data: { ...fields, admin_email },
  };

  try {
    let api = await axios(args);
    console.log(api, 'check add processor,', fields);
    if (api.status == 200) {
      toast.success(api?.data?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const deleteQueues = (queue) => async () => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.delete_queues}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    data: { queues: queue, admin_email },
  };

  try {
    let api = await axios(args);
    console.log(api, 'check add processor,', queue);
    if (api.status == 200) {
      toast.success(api?.data?.result?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Edit / Update Queue
export const updateQueue = (data) => async () => {
  const args = {
    method: 'post',
    url: `${PRODUCT_URL}${PATH.edit_queue}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    data: { ...data, admin_email },
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.message);
      return true;
    } else {
      toast.error(api?.data?.message);
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// Get Document type for queues
export const getDocumentTypeForQueue = () => async (dispatch) => {
  const args = {
    method: 'GET',
    url: `${PRODUCT_URL}${PATH.queues_and_documents}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log('check Api', api);
      dispatch({
        type: queuesActionType.GET_DOCUMENTTYPE_FOR_QUEUE,
        payload: api.data,
      });
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: queuesActionType.GET_DOCUMENTTYPE_FOR_QUEUE,
        payload: {},
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: queuesActionType.GET_DOCUMENTTYPE_FOR_QUEUE,
        payload: {},
      });
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
