import * as fieldsActionType from './fieldsActionTypes';

const initialFieldState = {
  field_data: { Fields: {} },
  add_field_data: {
    name: '',
    category: '',
    // dataType: '',
  },
};

const fieldsReducer = (state = initialFieldState, { type, payload }) => {
  switch (type) {
    case fieldsActionType.FIELD_DATA:
      return {
        ...state,
        field_data: payload,
      };
    case fieldsActionType.ADD_FIELD_DATA:
      return {
        ...state,
        add_field_data: payload,
      };
    case fieldsActionType.UPDATE_FIELD_DATA:
      return {
        ...state,
        update_field_data: payload,
      };

    default:
      return state;
  }
};

export default fieldsReducer;
