export const REVIEW_DASHBOARD = 'REVIEW_DASHBOARD';
export const TO_BE_REVIEW_DASHBOARD='TO_BE_REVIEW_DASHBOARD';
export const PASSWORD_REQUIRED='PASSWORD_REQUIRED';
export const PENDING_DASHBOARD = 'PENDING_DASHBOARD';
export const PENDING_ITEMS = 'PENDING_ITEMS';
export const EXPORT_STATUS = 'EXPORT_STATUS';
export const EXPORT_BATCH = 'EXPORT_BATCH';
export const DELETE_STATUS = 'DELETE_STATUS';
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const ADMIN_UPLOAD_DASHBOARD = 'ADMIN_UPLOAD_DASHBOARD';
export const OPEN_CASES_DASHBOARD = 'OPEN_CASES_DASHBOARD';
export const IS_ADMIN = 'IS_ADMIN';
export const GET_MIS_DASHBOARD = 'GET_MIS_DASHBOARD';
export const REJECTED_STATUS = 'REJECTED_STATUS';
export const REVIEW_DASHBOARD_LENGTH = 'REVIEW_DASHBOARD_LENGTH';
export const TO_BE_REVIEW_DASHBOARD_LENGTH='TO_BE_REVIEW_DASHBOARD_LENGTH';
export const PASSWORD_REQUIRED_LENGTH='PASSWORD_REQUIRED_LENGTH';
export const PENDING_DASHBOARD_LENGTH = 'PENDING_DASHBOARD_LENGTH';
export const REJECTED_STATUS_LENGTH = 'REJECTED_STATUS_LENGTH';
export const EXPORT_STATUS_LENGTH = 'EXPORT_STATUS_LENGTH';
export const DELETE_STATUS_LENGTH = 'DELETE_STATUS_LENGTH';
export const SEARCH_STATUS = 'SEARCH_STATUS';
export const SEARCH_LENGTH = 'SEARCH_LENGTH';
