import * as ActionType from './CommonActionType';
const initialState = {
  pagination: {
    start: 0,
    end: 0,
    total: 0,
  },
  isEditing: false,
};

const CommonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionType.PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionType.IS_EDITING:
      return {
        ...state,
        isEditing: payload,
      };
    default:
      return state;
  }
};

export default CommonReducer;
