import ReactDOM from "react-dom";
import React from "react";
import './Modal.css';
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { handleEditing } from "../../Store/Common/CommonAction";

const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen,
  dispatch
) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
    dispatch(handleEditing(false))
  };

  const handleCancel = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  document.body.appendChild(container);
  const { header, content } = JSON.parse(message);
  ReactDOM.render(
    <ConfirmDeleteModal
      open={confirmOpen}
      handleClick={handleConfirm}
      handleClose={handleCancel}
      desc={content}
      header={header}
      text={'EXIT ANYWAY'}
    />,
    container
  );
};

export default UserLeaveConfirmation;