import * as ActionType from './userActionTypes';

const initialQueuesState = {
  user: [],
  delete_status: [],
  review: [],
  toBeReview:[],
  password_required:[],
  pending: [],
  pending_items: [],
  export_status: [],
  admin_upload_dashboard_search_term: '',
  rejected_status:[],
  review_length: [],
  toBeReview_length: [],
  password_required_length: [],
  pending_length: [],
  export_status_length: [],
  delete_status_length: [],
  rejected_status_length: [],
  search_status:[],
  search_lenght:[]
};

const userReducer = (state = initialQueuesState, { type, payload }) => {
  console.log(type);

  switch (type) {
    case ActionType.REVIEW_DASHBOARD:
      return {
        ...state,
        review: payload,
      };
    case ActionType.REVIEW_DASHBOARD_LENGTH:
      return {
        ...state,
        review_length: payload,
      };
    case ActionType.TO_BE_REVIEW_DASHBOARD:
      return {
        ...state,
        toBeReview: payload,
      };
    case ActionType.TO_BE_REVIEW_DASHBOARD_LENGTH:
      return {
        ...state,
        toBeReview_length: payload,
      };
    case ActionType.PASSWORD_REQUIRED: //PASSWORD_REQUIRED_LENGTH
      return {
        ...state,
        password_required: payload,
      };
    case ActionType.PASSWORD_REQUIRED_LENGTH:
      return {
       ...state,
       password_required_length: payload,
     };
    case ActionType.PENDING_DASHBOARD:
      return {
        ...state,
        pending: payload,
      };
    case ActionType.PENDING_DASHBOARD_LENGTH:
      return {
        ...state,
        pending_length: payload,
      };
      case ActionType.PENDING_ITEMS:
        return {
          ...state,
          pending_items: payload,
        };
    case ActionType.EXPORT_STATUS:
      return {
        ...state,
        export_status: payload,
      };
    case ActionType.EXPORT_STATUS_LENGTH:
      return {
        ...state,
        export_status_length: payload,
      };
    case ActionType.DELETE_STATUS:
      return {
        ...state,
        delete_status: payload,
      };
    case ActionType.DELETE_STATUS_LENGTH:
      return {
        ...state,
        delete_status_length: payload,
      };
    case ActionType.REJECTED_STATUS:
      return {
        ...state,
        rejected_status: payload,
      }; 
    case ActionType.REJECTED_STATUS_LENGTH:
      return {
        ...state,
        rejected_status_length: payload,
      }; 
    case ActionType.SEARCH_STATUS:
        return {
          ...state,
          search_status: payload,
        }; 
        case ActionType.SEARCH_LENGTH:
          return {
            ...state,
            search_lenght: payload,
          }; 
    case ActionType.ADMIN_UPLOAD_DASHBOARD:
      return {
        ...state,
        admin_upload_dashboard: payload,
      };
    case ActionType.OPEN_CASES_DASHBOARD:
      return {
        ...state,
        open_cases_dashboard: payload,
      };
    case ActionType.GET_MIS_DASHBOARD:
      return {
        ...state,
        mis_dashboard: payload,
      };
    case ActionType.IS_ADMIN:
      return {
        ...state,
        isAdmin: payload,
      };
    case 'TRACK_SERCH_TERM':
      return {
        ...state,
        admin_upload_dashboard_search_term: payload,
      };
    case 'CLEAR_SERCH_TERM':
      return {
        ...state,
        admin_upload_dashboard_search_term: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
