import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './Modal.css';
import ButtonPrimary from '../Buttons/ButtonPrimary';
const ConfirmDeleteModal = ({
  handleClose,
  handleClick,
  text,
  header,
  desc,
  open,
  disabled
}) => {
  return (
    <div>
      <div className='data' style={{ width: '100%' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={'paper'}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='logout_popup'
          style={{ overflow: 'scroll' }}
        >
          <div style={{ width: '100%' }}>
            <DialogTitle id='alert-dialog-title'>{header}</DialogTitle>
            <hr className='logoutborder' />
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {desc}
              </DialogContentText>
            </DialogContent>
            <DialogActions className='modalfooter'>
              <React.Fragment>
                <Button
                  onClick={handleClose}
                  color='primary'
                  className='cancelbutton'
                >
                  Cancel
                </Button>
                <ButtonPrimary disabled={disabled} onClick={handleClick} actualText={text} />
              </React.Fragment>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
// Usage
{
  /* <ConfirmDeleteModal
handleClick={handleClick}
handleClose={handleClose}
desc={'Are you sure you want to delete'}
header={'Confirm Delete'}
modal={showModal}
text='Delete'
/> */
}
