import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RootRouter from './Routers/RootRouter';
import './App.css';
import { useHistory } from 'react-router-dom';

import { handleResetSearchTerm } from './Store/User/userAction';
import { toast } from 'react-toastify';
// console.log = function () {};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.onpopstate = (e) => {
      console.log(window,'back pressed');
      dispatch(handleResetSearchTerm());
    };
  }
  );
  setTimeout(() => {
    toast.warning('Session is Going to Expire in 5mins');
  }, 3300000) 
  //3300000

  return (
    <div className='App'>
      <RootRouter />
    </div>
  );
}

export default App;
