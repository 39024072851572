import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import _store from './Store';
const { persistor, Store } = _store();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
  // production code
  console.log = () => { };
} else {
  // dev code
  // console.log = () => {};
}
ReactDOM.render(
  // <Provider store={Store}>
  //     <App />
  // </Provider>,
  <PersistGate persistor={persistor}>
    <Provider store={Store}>
      <App />
    </Provider>
  </PersistGate>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
