import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './Modal.css';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import { TextareaAutosize } from '@material-ui/core';
const ReportModal = ({ handleClose, handleClick, text, open }) => {
  return (
    <div>
      <div className='data' style={{ width: '100%' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={'paper'}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='logout_popup'
          style={{ overflow: 'scroll' }}
        >
          <div style={{ width: '100%' }}>
            <DialogTitle id='alert-dialog-title'>Report a problem</DialogTitle>
            <hr className='logoutborder' />
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Help us uncover the issue. Describe the situation in as much
                detail possible (e.g. actions you were about to perform etc.)
              </DialogContentText>
              <TextareaAutosize
                maxRows={4}
                rows={3}
                aria-label='maximum height'
                defaultValue=''
                style={{ width: '100%' }}
              />
            </DialogContent>
            <DialogActions className='modalfooter'>
              <React.Fragment>
                <Button
                  onClick={handleClose}
                  color='primary'
                  className='cancelbutton'
                >
                  Cancel
                </Button>
                <ButtonPrimary onClick={handleClick} actualText='SEND' />
              </React.Fragment>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ReportModal;
// Usage
{
  /* <ConfirmDeleteModal
handleClick={handleClick}
handleClose={handleClose}
desc={'Are you sure you want to delete'}
header={'Confirm Delete'}
modal={showModal}
text='Delete'
/> */
}
