import * as digitiseActionTypes from './digitizeActionType';

const initialDigitzeState = {
  ui_validation: {},
  header_fields: [],
  mandatory_columns: [],
  mandatory_table_headers: [],
  pages: [],
  pages_count: 0,
  file_name: '',
  active_page: 0,
  activeJob: '',
  activeDocType: '',
  zoom: 0,
  setWidth: true,
  logicalTokens: [],
  isPopperVisible: false,
  rndFlag: false,
  result: {},
  drop_down_value: [],
  dragMove: false,
  edited_header_fields: [],
  isDoneButtonEnabled: false,
  flagInput: true,
  reloadFlag: false,
  graphs: {},
  current_header: {
    coordinates: { top: 0, left: 0, width: 0, height: 0 },
    order_id: 0,
    page_number: 1,
    confidence_score: 0,
    confidence_score_green: 0,
    label: '',
    value: '',
  },
  headerError: false,
  errorList: [[]],
  dateList: [],
  dragMove: false,
  setactivepage: true,
  header_field_errors: [],
};

const digitizeReducer = (state = initialDigitzeState, { type, payload }) => {
  switch (type) {
    case digitiseActionTypes.UI_VALIDATION:
      return {
        ...state,
        ui_validation: payload,
      };
    case digitiseActionTypes.HEADER_FIELDS:
      return {
        ...state,
        header_fields: payload,
      };
    case digitiseActionTypes.MANDATORY_COLUMNS:
      return {
        ...state,
        mandatory_columns: payload,
      };
    case digitiseActionTypes.MANDATORY_TABLE_HEADERS:
      return {
        ...state,
        mandatory_table_headers: payload,
      };
    case digitiseActionTypes.PAGES:
      return {
        ...state,
        pages: payload,
      };
    case digitiseActionTypes.DROP_DOWN_VALUE:
      return {
        ...state,
        drop_down_value: payload,
      };
    case digitiseActionTypes.RESULT:
      return {
        ...state,
        result: payload,
      };

    case digitiseActionTypes.FILE_NAME:
      return {
        ...state,
        file_name: payload,
      };
    case digitiseActionTypes.PAGES_COUNT:
      return {
        ...state,
        pages_count: payload,
      };
    case digitiseActionTypes.PRE_IMAGE_DETIALS:
      return {
        ...state,
        pre_image: payload,
      };
    case digitiseActionTypes.ACTIVE_PAGE:
      return {
        ...state,
        active_page: payload,
      };
    case digitiseActionTypes.ACTIVE_JOB:
      return {
        ...state,
        activeJob: payload,
      };
    case digitiseActionTypes.ACTIVE_DOC_TYPE:
      return {
        ...state,
        activeDocType: payload,
      }
    case digitiseActionTypes.CURRENT_HEADER:
      return {
        ...state,
        current_header: payload,
      };
    case digitiseActionTypes.ZOOM:
      return {
        ...state,
        zoom: payload,
      };
    case digitiseActionTypes.SETWIDTH:
      return {
        ...state,
        setWidth: payload,
      };
    case digitiseActionTypes.LOGICAL_TOKENS:
      return {
        ...state,
        logicalTokens: payload,
      };
    case digitiseActionTypes.IS_POPPER_VISIBLE:
      return {
        ...state,
        isPopperVisible: payload,
      };
    case digitiseActionTypes.RND_FLAG:
      return {
        ...state,
        rndFlag: payload,
      };
    case digitiseActionTypes.SET_ACTIVE_PAGE:
      return {
        ...state,
        setactivepage: payload,
      };
    case digitiseActionTypes.HEADER_FIELD_ERRORS:
      return {
        ...state,
        header_field_errors: payload,
      };
    case digitiseActionTypes.DRAG_MOVE:
      return {
        ...state,
        dragMove: payload,
      };
    case digitiseActionTypes.EDITED_HEADER_FIELDS:
      return {
        ...state,
        edited_fields: payload,
      };
    case digitiseActionTypes.FLAG_INPUT:
      return {
        ...state,
        flagInput: payload,
      };
    case digitiseActionTypes.RELOAD_FLAG:
      return {
        ...state,
        reloadFlag: payload,
      };
    case digitiseActionTypes.GET_GRAPHS:
      return {
        ...state,
        graphs: payload,
      };
      case digitiseActionTypes.SHOW_DONE_BUTTON:
      return { ...state, isDoneButtonEnabled: payload };
    // case digitiseActionTypes.GET_EXTRACTED_DATA_SUCCESS:
    // return { ...state, extractedData: payload };
    case digitiseActionTypes.SHOW_DONE_BUTTON:
      return { ...state, isDoneButtonEnabled: payload };
    case digitiseActionTypes.HEADER_ERROR:
      return { ...state, headerError: payload };
    case digitiseActionTypes.ERROR_LIST:
      return { ...state, errorList: payload };
    case digitiseActionTypes.DATE_LIST:
      return { ...state, dateList: payload };
    case 'DISABLEVALIDATE':
      return { ...state, validatebutton: payload };
    default:
      return state;
  }
};

export default digitizeReducer;
